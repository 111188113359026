var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"row page-title-header"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"page-header"},[_c('b-breadcrumb',{staticClass:"m-0"},[_c('b-breadcrumb-item',{attrs:{"to":{
              name: 'DashboardHome',
            }}},[_c('i',{staticClass:"fa fa-home"})]),_c('b-breadcrumb-item',{attrs:{"active":""}},[_vm._v("自動化模組")]),_c('b-breadcrumb-item',{attrs:{"active":""}},[_vm._v("會員事件設定")])],1)],1)])]),_c('b-card',[_c('div',{staticClass:"row d-flex mb-4 mb-xl-2 justify-content-between"},[_c('h4',{staticClass:"col-12 col-xl-2 mb-2 font-weight-bold"},[_vm._v("會員事件設定")]),_c('div',{staticClass:"\n        col-12 col-xl-6 mb-2\n        d-flex\n        flex-xl-row\n        align-items-start align-items-xl-center\n        "},[_c('b-input-group',[_c('b-form-checkbox-group',{attrs:{"options":_vm.typeOptions},on:{"change":_vm.fetchData},model:{value:(_vm.type),callback:function ($$v) {_vm.type=$$v},expression:"type"}})],1),_c('b-button',{directives:[{name:"b-modal",rawName:"v-b-modal.modal-show",modifiers:{"modal-show":true}}],staticClass:"flex-shrink-0 mb-2 mb-xl-0 ml-3",attrs:{"variant":"primary"}},[_vm._v("新增事件")])],1)]),_c('b-card-text',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('b-overlay',{attrs:{"show":_vm.showLoading,"rounded":"sm"}},[_c('b-table',{attrs:{"striped":"","hover":"","responsive":"","items":_vm.items,"fields":_vm.fields},scopedSlots:_vm._u([{key:"cell(actions)",fn:function(data){return [(_vm.checkPermission([_vm.consts.MKT_CONFIG_LIST_VIEW]))?_c('b-button',{staticClass:"mr-1",attrs:{"variant":"inverse-primary","to":{
                  name: 'AutoMarketingView',
                  params: {
                    config_id: data.item.id
                  }
                }}},[_c('span',{staticClass:"mdi mdi-eye"}),_vm._v(" 查看 ")]):_vm._e(),(_vm.checkPermission([_vm.consts.MKT_CONFIG_LIST_MODIFY]))?_c('b-button',{staticClass:"mr-1",attrs:{"variant":"inverse-warning","to":{
                  name: 'AutoMarketingEdit',
                  params: {
                    config_id: data.item.id
                  }
                }}},[_c('span',{staticClass:"mdi mdi-lead-pencil"}),_vm._v(" 編輯 ")]):_vm._e(),(_vm.checkPermission([_vm.consts.MKT_CONFIG_LIST_DELETE]))?_c('b-button',{attrs:{"variant":"inverse-danger"},on:{"click":function($event){return _vm.handleDelete(data.item)}}},[_c('span',{staticClass:"mdi mdi-delete"}),_vm._v(" 刪除 ")]):_vm._e()]}}])})],1)],1)])])],1),_c('b-modal',{attrs:{"id":"modal-show","ok-title":"關閉","centered":"","ok-only":""}},[_c('b-card',{staticClass:"mb-2",attrs:{"title":"好友事件"}},[_c('div',[_vm._v("透過好友禮，來吸引訪客新註冊為品牌會員。提高註冊轉換率，以及會員數量。")]),_c('b-button',{staticClass:"flex-shrink-0 mb-2 mb-xl-0",attrs:{"variant":"primary","to":{
          name: 'AutoMarketingMemberEventForm',
          params: {
            type: 'line_follow'
          }
        }}},[_vm._v("新增")])],1),_c('b-card',{staticClass:"mb-2",attrs:{"title":"註冊事件"}},[_c('div',[_vm._v("透過好友禮，來吸引訪客新註冊為品牌會員。提高註冊轉換率，以及會員數量。")]),_c('b-button',{staticClass:"flex-shrink-0 mb-2 mb-xl-0",attrs:{"variant":"primary","to":{
          name: 'AutoMarketingMemberEventForm',
          params: {
            type: 'registered'
          }
        }}},[_vm._v("新增")])],1),_c('b-card',{staticClass:"mb-2",attrs:{"title":"生日事件"}},[_c('div',[_vm._v("透過好友禮，來吸引訪客新註冊為品牌會員。提高註冊轉換率，以及會員數量。")]),_c('b-button',{staticClass:"flex-shrink-0 mb-2 mb-xl-0",attrs:{"variant":"primary","to":{
          name: 'AutoMarketingMemberEventForm',
          params: {
            type: 'birthday'
          }
        }}},[_vm._v("新增")])],1),_c('b-card',{attrs:{"title":"封鎖事件"}},[_c('div',[_vm._v("透過好友禮，來吸引訪客新註冊為品牌會員。提高註冊轉換率，以及會員數量。")]),_c('b-button',{staticClass:"flex-shrink-0 mb-2 mb-xl-0",attrs:{"variant":"primary","to":{
          name: 'AutoMarketingMemberEventForm',
          params: {
            type: 'line_unfollow'
          }
        }}},[_vm._v("新增")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }