<template>
  <div>
    <div class="row page-title-header">
      <div class="col-12">
        <div class="page-header">
          <b-breadcrumb class="m-0">
            <b-breadcrumb-item
              :to="{
                name: 'DashboardHome',
              }"
            >
              <i class="fa fa-home"></i>
            </b-breadcrumb-item>

            <b-breadcrumb-item active>自動化模組</b-breadcrumb-item>
            <b-breadcrumb-item active>會員事件設定</b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </div>
    </div>

    <b-card>
      <div class="row d-flex mb-4 mb-xl-2 justify-content-between">
        <h4 class="col-12 col-xl-2 mb-2 font-weight-bold">會員事件設定</h4>
        <div class="
          col-12 col-xl-6 mb-2
          d-flex
          flex-xl-row
          align-items-start align-items-xl-center
          "
        >
          <b-input-group>
            <b-form-checkbox-group v-model="type" :options="typeOptions" @change="fetchData"></b-form-checkbox-group>
            <!-- <b-form-input v-model="keyword" placeholder="輸入活動名稱"></b-form-input> -->
            <!-- <b-input-group-append>
              <b-button><i class="fa fa-search"></i></b-button>
            </b-input-group-append> -->
          </b-input-group>
          <b-button
            class="flex-shrink-0 mb-2 mb-xl-0 ml-3"
            variant="primary"
            v-b-modal.modal-show
            >新增事件</b-button
          >
        </div>
      </div>
      <b-card-text>
        <div class="row">
          <div class="col-12">
            <b-overlay :show="showLoading" rounded="sm">
              <b-table
                striped
                hover
                responsive
                :items="items"
                :fields="fields"
              >
              <template #cell(actions)="data">
                <b-button
                  v-if="checkPermission([consts.MKT_CONFIG_LIST_VIEW])"
                  class="mr-1"
                  variant="inverse-primary"
                  :to="{
                    name: 'AutoMarketingView',
                    params: {
                      config_id: data.item.id
                    }
                  }"
                >
                  <span class="mdi mdi-eye"></span> 查看
                </b-button>
                <b-button
                  v-if="checkPermission([consts.MKT_CONFIG_LIST_MODIFY])"
                  class="mr-1"
                  variant="inverse-warning"
                  :to="{
                    name: 'AutoMarketingEdit',
                    params: {
                      config_id: data.item.id
                    }
                  }"
                >
                  <span class="mdi mdi-lead-pencil"></span> 編輯
                </b-button>
                <b-button 
                  v-if="checkPermission([consts.MKT_CONFIG_LIST_DELETE])"
                  variant="inverse-danger"
                  @click="handleDelete(data.item)">
                  <span class="mdi mdi-delete"></span> 刪除
                </b-button>
              </template>
              </b-table>
            </b-overlay>
          </div>
        </div>
      </b-card-text>
    </b-card>

    <b-modal id="modal-show" ok-title="關閉" centered ok-only>
      <b-card title="好友事件" class="mb-2">
        <div>透過好友禮，來吸引訪客新註冊為品牌會員。提高註冊轉換率，以及會員數量。</div>
        <b-button
          class="flex-shrink-0 mb-2 mb-xl-0"
          variant="primary"
          :to="{
            name: 'AutoMarketingMemberEventForm',
            params: {
              type: 'line_follow'
            }
          }"
          >新增</b-button
        >
      </b-card>

      <b-card title="註冊事件" class="mb-2">
        <div>透過好友禮，來吸引訪客新註冊為品牌會員。提高註冊轉換率，以及會員數量。</div>
        <b-button
          class="flex-shrink-0 mb-2 mb-xl-0"
          variant="primary"
          :to="{
            name: 'AutoMarketingMemberEventForm',
            params: {
              type: 'registered'
            }
          }"
          >新增</b-button
        >
      </b-card>

      <b-card title="生日事件" class="mb-2">
        <div>透過好友禮，來吸引訪客新註冊為品牌會員。提高註冊轉換率，以及會員數量。</div>
        <b-button
          class="flex-shrink-0 mb-2 mb-xl-0"
          variant="primary"
          :to="{
            name: 'AutoMarketingMemberEventForm',
            params: {
              type: 'birthday'
            }
          }"
          >新增</b-button
        >
      </b-card>

      <b-card title="封鎖事件">
        <div>透過好友禮，來吸引訪客新註冊為品牌會員。提高註冊轉換率，以及會員數量。</div>
        <b-button
          class="flex-shrink-0 mb-2 mb-xl-0"
          variant="primary"
          :to="{
            name: 'AutoMarketingMemberEventForm',
            params: {
              type: 'line_unfollow'
            }
          }"
          >新增</b-button
        >
      </b-card>
    </b-modal>
  </div>
</template>

<script>
import moment from 'moment';
import * as consts from '@/consts'
import PermissionChecker from '@/utils/PermissionChecker'
import autoMarketingApi from "@/apis/auto-marketing";

  export default {
    name: "AutoMarketingMemberEvent",
    computed: {
      typeOptions() {
        return [
          {
            value: "line_follow",
            text: "新好友",
          },
          {
            value: "registered",
            text: "會員註冊",
          },
          {
            value: "birthday",
            text: "會員生日",
          },
          {
            value: "line_unfollow",
            text: "用戶封鎖",
          }
        ]
      },
    },
    data() {
      return {
        consts: consts,
        showLoading: false,
        type: ['line_follow', 'registered', 'birthday', 'line_unfollow'],
        // keyword: "",
        items: [
          {
            id: 9725,
            title: "測試-生日禮",
            type: "生日禮",
            status: "已生效",
            start_at: "2023/11/08 00:00",
            end_at: "2023/12/08 00:00",
          }
        ],
        fields: [
          {
            key: "name",
            label: "名稱",
            formatter: (_, key, item) => {
              return `${item.title}`
            }
          },
          {
            key: "type",
            label: "類別",
            formatter: (_, key, item) => this.typeOptions.find((option) => option.value === item.type).text
          },
          {
            key: "status",
            label: "狀態",
            formatter: (_, key, item) => item.is_enabled ? "啟用" : "未啟用"
          },
          {
            key: "period",
            label: "使用區間",
            formatter: (_, key, item) => `${moment(item.start_at).format("YYYY-MM-DD HH:mm")} ~ ${moment(item.end_at).format("YYYY-MM-DD HH:mm")}`
          },
          {
          key: "actions",
          label: "管理",
        },
        ],
      }
    },
    mounted() {
      this.fetchData()
    },
    methods: {
      checkPermission (permissions) {
        const checker = new PermissionChecker;
        return checker.check(permissions);
      },
      async fetchData() {
        this.showLoading = true
        try {
          const res = await autoMarketingApi.getMarketingConfigList({
            type: this.type
          })
          this.items = res.data.data
        } catch (error) {
          console.error(error)
        } finally {
          this.showLoading = false
        }
      },

      async handleDelete(config) {
        await this.$swal
          .fire({
            title: "你確定要刪除嗎？",
            html: `
              <div class="d-block">
                <div class="my-3">
                  <div>事件名稱：${config.title}</div>
                </div>
                <div>刪除後，相關資料將無法回復，<br/>請您確認！</div>
              </div>`,
            type: "warning",
            confirmButtonText: "確定刪除",
            cancelButtonText: "不刪除",
            showCancelButton: true,
            confirmButtonColor: "#d33",
            cancelButtonColor: "#3085d6",
            reverseButtons: true,
          })
          .then(async (result) => {
            if (result.value) {
              let response = await autoMarketingApi.removeMarketingConfig(config);
              if (response.status === 200) {
                this.$swal.fire({
                  title: "成功",
                  type: "success",
                  text: "成功刪除 " + config.title,
                });
                this.fetchData();
              } else {
                if (response.data.message) {
                  this.$swal.fire({
                    title: '錯誤',
                    type: "error",
                    text: response.data.message,
                  });
                }
              }
            }
          });
      },
    },
  }
</script>

<style lang="scss" scoped>

</style>
